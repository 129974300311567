<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>

        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('Today')"
                >
                  วันนี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('Yesterday')"
                >
                  เมื่อวาน
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('ThisWeek')"
                >
                  อาทิตย์นี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('LastWeek')"
                >
                  อาทิตย์ที่แล้ว
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('ThisMonth')"
                >
                  เดือนนี้
                </b-button>
              </div>
              <div class="col-md-6 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  @click="getwinloss('LastMonth')"
                >
                  เดือนที่แล้ว
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">

                <b-form-group>
                  <flat-pickr
                    v-model="rangeDate"
                    placeholder="เลือกวันที่"
                    class="form-control"
                    :config="{
                      mode: 'range',
                      enableTime: true,
                      dateFormat: 'Y-m-d',
                      time_24hr: true,
                      defaultHour: 0,
                    }"
                  />
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  block
                  @click="RangDateCal()"
                >
                  <feather-icon icon="SearchIcon" />
                  Search
                </b-button>
              </div>
              <!-- <feather-icon
                icon="SearchIcon"
                size="16"
                class="mx-1"
              /> -->
            </div>
          </div>
          <div class="col-md-9">
            <b-form-group
              class="mr-1 mb-md-0"
            >

              <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="toggleAll"
              >
                ทั้งหมด
              </b-form-checkbox>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="options"
                value-field="name"
                text-field="name"
                name="flavour-1"
                class="demo-inline-spacing"
                @change="getwinloss()"
              />
            </b-form-group>
            <!-- Selected: <strong>{{ selected }}</strong> -->
          </div>
        </div>

      </b-card-body>

      <div style="overflow-x:auto;">
        <table
          id="zero_config"
          class="table table-bordered table-sm table-striped table-hover"
        >
          <thead>
            <tr class="text-center">
              <th
                rowspan="2"
                class="align-middle"
              >
                #
              </th>
              <th
                rowspan="2"
                class="align-middle"
              >
                ยูสเซอร์เนม
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                WIN/LOSS
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                ส่วนลด
              </th>
              <!-- <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                รอผล
              </th>
              <th
                rowspan="2"
                class="align-middle"
                style=""
              >
                ส่วนลดรอผล
              </th> -->
              <th
                colspan="3"
                class="align-middle bg-warning"
              >
                Agent
              </th>
              <th
                colspan="3"
                class="align-middle"
              >
                Company
              </th>
            </tr>
            <tr class="text-center">
              <th
                style=""
                class="bg-warning"
              >
                W/L
              </th>
              <th
                style=""
                class="bg-warning"
              >
                ส่วนลด
              </th>
              <th
                style=""
                class="bg-warning"
              >
                total
              </th>
              <th style="">
                W/L
              </th>
              <th style="">
                ส่วนลด
              </th>
              <th style="">
                total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data,index) in listdata"
              :key="data"
              class="text-center font-weight-bolder"
            >
              <td>{{ index + 1 }}</td>
              <th scope="row">
                <router-link
                  :to="{ name: 'Deposit-Withdraw', params: { id: data.username }}"
                  style="color: blue"
                >
                  {{ data.username }}
                </router-link>
              </th>
              <td
                v-if="data.winloss < 0"
                style="color:red"
              >{{ data.winloss.toLocaleString() }}</td>
              <td
                v-else
              >{{ data.winloss.toLocaleString() }}</td>
              <td>
                {{ data.discount.toLocaleString() }}
              </td>
              <!-- <td
                v-if="data.waiting < 0"
                style="color:red"
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td
                v-else
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td>
                {{ data.witingdiscount.toLocaleString() }}
              </td> -->
              <td
                v-if="data.winloss_agent < 0"
                style="color:red"
              >{{ data.winloss_agent.toLocaleString() }}</td>
              <td
                v-else
              >{{ data.winloss_agent.toLocaleString() }}</td>
              <td>{{ data.dis_agent.toLocaleString() }}</td>
              <td
                v-if="data.total_agent < 0"
                style="color:red"
              >{{ data.total_agent.toLocaleString() }}</td>
              <td v-else>
                {{ data.total_agent.toLocaleString() }}
              </td>
              <td
                v-if="data.winloss_com < 0"
                style="color:red"
              >{{ data.winloss_com.toLocaleString() }}</td>
              <td
                v-else
              >{{ data.winloss_com.toLocaleString() }}</td>
              <td>{{ data.dis_com.toLocaleString() }}</td>
              <td
                v-if="data.total_com < 0"
                style="color:red"
              >{{ data.total_com.toLocaleString() }}</td>
              <td v-else>
                {{ data.total_com.toLocaleString() }}
              </td>
              <!-- <td>{{ }}</td> -->
            </tr>
          </tbody>
          <tfoot>
            <tr class="text-right font-weight-bold text-white">
              <td
                colspan="2"
                class="text-center"
              >
                ทั้งหมด
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.winloss), 0) < 0"
                     class="text-center font-weight-bolder"
                     style="color:red"
                   >
                     {{ listdata.reduce((total, item) => total + Number(item.winloss), 0).toFixed(2) }}<span />
                   </span>
                <span
                  v-else
                  class="text-center font-weight-bolder"
                >
                  {{ listdata.reduce((total, item) => total + Number(item.winloss), 0).toFixed(2) }}<span />
                </span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + Number(item.discount), 0).toFixed(2) }}
                     <span
                       style="text-decoration: underline; cursor:pointer;"
                       onclick="if (!window.__cfRLUnblockHandlers) return false; changeFull($(this));"
                     />
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <!-- <td>{{ listdata.reduce((total, item) => total + Number(item.waiting), 0).toFixed(2) }}</td>
              <td>{{ listdata.reduce((total, item) => total + Number(item.witingdiscount), 0).toFixed(2) }}</td> -->
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.winloss_agent), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ listdata.reduce((total, item) => total + Number(item.winloss_agent), 0).toFixed(2) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ listdata.reduce((total, item) => total + Number(item.winloss_agent), 0).toFixed(2) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + Number(item.dis_agent), 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.total_agent), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ listdata.reduce((total, item) => total + Number(item.total_agent), 0).toFixed(2) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ listdata.reduce((total, item) => total + Number(item.total_agent), 0).toFixed(2) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.winloss_com), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ listdata.reduce((total, item) => total + Number(item.winloss_com), 0).toFixed(2) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                >
                  {{ listdata.reduce((total, item) => total + Number(item.winloss_com), 0).toFixed(2) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span class=" Simple">
                     {{ listdata.reduce((total, item) => total + Number(item.dis_com), 0).toFixed(2) }}
                   </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
              <td> <span
                     v-if="listdata.reduce((total, item) => total + Number(item.total_com), 0) < 0"
                     class=" Simple"
                     style="color:red"
                   >
                     {{ listdata.reduce((total, item) => total + Number(item.total_com), 0).toFixed(2) }}
                   </span>
                <span
                  v-else
                  class=" Simple"
                  style="color:red"
                >
                  {{ listdata.reduce((total, item) => total + Number(item.total_com), 0).toFixed(2) }}
                </span>
                <span
                  class=" Full"
                  style="display:none;"
                >0.0000</span>
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- <table>
          <thead>
            <tr>
              <th>ยูสเซอร์เนม</th>
              <th>WIN/LOSS</th>
              <th>ส่วนลด</th>
              <th>รอผล</th>
              <th>ส่วนลดรอผล</th>
              <th>Agent</th>
              <th>Company</th>
            </tr>
          </thead>
          <tbody>

            <tr
              v-for="(data) in listdata"
              :key="data._id"
            >
              <th scope="row">
                <router-link
                  :to="{ name: 'Deposit-Withdraw', params: { id: data.username }}"
                  style="color: blue"
                >
                  {{ data.username }}
                </router-link>
              </th>
              <td
                v-if="data.winloss < 0"
                style="color:red"
              >{{ data.winloss.toLocaleString() }}</td>
              <td
                v-else
              >{{ data.winloss.toLocaleString() }}</td>
              <td>
                {{ data.discount.toLocaleString() }}
              </td>
              <td
                v-if="data.waiting < 0"
                style="color:red"
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td
                v-else
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td>
                {{ data.witingdiscount.toLocaleString() }}
              </td>

            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th scope="row">
                ยอดรวม
              </th>
              <td
                v-if="twinloss < 0 "
                style="color: red"
              >
                {{ Number(twinloss).toLocaleString() }}
              </td>
              <td v-else>
                {{ Number(twinloss).toLocaleString() }}
              </td>
              <td>
                {{ Number(tdiscount).toLocaleString() }}
              </td>
              <td
                v-if="twaiting < 0 "
                style="color: red"
              >
                {{ Number(twaiting).toLocaleString() }}
              </td>
              <td v-else>
                {{ Number(twaiting).toLocaleString() }}
              </td>

              <td>{{ Number(twitingdiscount).toLocaleString() }} </td>
            </tr>
          </tfoot>
        </table> -->
      </div>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox, BFormCheckboxGroup, BInputGroupPrepend, BModal, BCard,
  // BTable, BAvatar,
  BFormGroup,
  // BFormSelect, BPagination,
  BInputGroup, BFormInput,
  // BInputGroupAppend,
  BButton,
  BCardBody, VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    flatPickr,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: true,
      rangeDate: null,
      days: null,
      twinloss: 0.00,
      twaiting: 0.00,
      tdiscount: 0.00,
      twitingdiscount: 0.00,
      selected: [],
      options: [],
      listdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'appid', label: 'appid' },
        { key: 'winloss', label: 'win/loss' },
        { key: 'discount', label: 'ส่วนลด' },
        { key: 'waiting', label: 'รอผล' },
        { key: 'witingdiscount', label: 'ส่วนลดรอผล' },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      allSelected: false,
      indeterminate: false,
      start: null,
      end: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.GetData()
    this.getwinloss()
  },
  methods: {
    RangDateCal() {
      // console.log(date)
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]

      this.start = start
      this.end = end
      // console.log(this.start, this.end)
      // eslint-disable-next-line no-bitwise
      if (this.start && this.end) {
        this.show = true
        // this.show = false
        const params = {
          selected: this.selected,
          start: this.start,
          end: this.end,
        }
        this.$http
          .get('history/showall', { params })
          .then(response => {
            this.listdata = response.data
            this.show = false
          // this.onFiltered(response.data)
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.listdata = []
          })
      }
    },
    wluser(username) {
      const paramss = {
        username,
        days: this.days,
        selected: this.selected,
      }
      this.$router.push('/win-loss-user')
      localStorage.setItem('wlParams', JSON.stringify(paramss))
    },
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
      this.getwinloss()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async GetData() {
      await this.$http
        .get('lottotype')
        .then(async response => {
          this.show = false
          // this.options = response.data
          // eslint-disable-next-line no-restricted-syntax
          for await (const da of response.data) {
            this.options.push(da.name)
          }
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getwinloss(days) {
      this.show = true
      // this.show = false
      const params = {
        selected: this.selected,
        days,
      }
      this.$http
        .get('history/showall', { params })
        .then(response => {
          this.listdata = response.data
          // this.twinloss = response.data.twinloss
          // this.twaiting = response.data.twaiting
          // this.tdiscount = response.data.tdiscount
          // this.twitingdiscount = response.data.twitingdiscount
          this.show = false
          // this.onFiltered(response.data)
        })
        .catch(error => {
          console.log(error)
          this.show = false
          this.listdata = []
          this.twinloss = 0.00
          this.twaiting = 0.00
          this.tdiscount = 0.00
          this.twitingdiscount = 0.00
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
thead,
tfoot {
  background-color: gold;
  color: rgb(0, 0, 0);
  text-align: center;
}

tbody {
  background-color: #f1e8e8;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  text-align: center;
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}
</style>
